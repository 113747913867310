
<template>
  <div>
    <div class="main-content" style="max-width: 1000px; position: relative">
          <Card class="card-custom card-block">
              <template #title>
                <div class="column-wrapper align-center">
                  <div class="column-three text-left"><back-button /></div>
                    <h4 class="c-black flex-1 column-three">{{$t('admin_data')}}</h4>
                    <div class="column-three"></div>
                </div>
              </template>
              <template #content>
                <ValidationObserver ref="observer" v-slot="{ invalid, validate }" v-if="!errorFlag">
                  <Skeleton width="100%" height="200px" v-if="loadingPage"></Skeleton>
                  <Card class="card-custom form-block" v-if="!loadingPage">
                    <template #content>
                    <div class="space-y-1">
                  <ValidationProvider
                  :name="$t('role')"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space title-input">
                    {{ $t('role') }}<span class="c-red">*</span>:
                  </h4>
                  
                  <div class="wrapper-input d-flex">
                    <Dropdown
                    optionLabel="text"
                    optionValue="value"
                    v-if="admin.role && admin.role.id"
                    v-model="getRoleAdmin"
                  :options="getFormattedRoles"
                  class="custom-dropdown"
                  style="flex: 1 1 auto"
                  :placeholder="$t('role')"
                  :filter="true"
                  :emptyFilterMessage="$t('no_data')"
                >
                </Dropdown>
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                  <ValidationProvider
                    :name="$t('login')"
                    rules="required|min3"
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">
                      {{ $t('login') }}<span class="c-red">*</span>:
                    </h4>
                    
                    <div class="wrapper-input">
                      <InputText 
                      autocomplete="off"
                      :maxlength="12"
                      v-model="admin.login" 
                      class="p-inputtext-sm fw"
                      :class="{ 'p-invalid' : errors.length > 0 }"
                        :placeholder="$t('login')" 
                        
                        />
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                <ValidationProvider
                :name="$t('old_password')"
                rules="required|min8"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">
                  {{ $t('old_password') }}<span class="c-red">*</span>:
                </h4>
                <div class="wrapper-input">
                  <Password 
                  autocomplete="off"
                  v-model="admin.current_password"
                  :class="{ 'p-invalid' : errors.length > 0 }"
                  class="fw" 
                  :placeholder="$t('old_password')" 
                  toggleMask 
                  :feedback="false"
                  />
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
                <ValidationProvider
                :name="$t('new_password')"
                rules="required|min8"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">
                  {{ $t('new_password') }}<span class="c-red">*</span>:
                </h4>
                <div class="wrapper-input">
                  <Password 
                  v-model="admin.password"
                  :class="{ 'p-invalid' : errors.length > 0 }"
                  class="fw" 
                  :placeholder="$t('new_password')" 
                  toggleMask 
                  :feedback="false"
                  />
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            <span
                        > 
                          <h4 class="c-text white-space title-input">
                            {{ $t('active') }}:
                          </h4>
                          
                          <div class="wrapper-input d-flex">
                            <InputSwitch class="p-inputswitch-success" v-model="admin.is_active" />
                          </div>
                      </span>
          </div>
          </template>
        </Card>
              <Button @click="handleSubmit(validate)" 
        :loading="loadingFlag || formLoading || loadingPage"
        :disabled="loadingFlag || formLoading || loadingPage"
                   :label="$t('edit')"
                    icon="pi pi-save"
         class="fw p-button-sm p-button-success mt-2" />
          </ValidationObserver>
          <no-work-page 
        v-else
        @refresh-request="toGetBonusByID" 
        :loadingFlag="loadingFlag"
        textError="just_error"
        />
                </template>
                </Card>
</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from "axios";
import disableAutocomplete from '@/disable-autocomplete';
export default {
    name: 'EditAdmin',
    computed: {
      ...mapGetters({
          roles: 'roles/getRole',
        }),
        getFormattedRoles(){
          return this.roles.map(role => {
            return {
              text: this.roleText(role.name),
              value: role.id
            }
          })
        },
        getRoleAdmin: {
          get(){
            return this.admin.role ? this.admin.role.id : null
          },
          set(value){
            this.admin.role_id = value;
          }
        },
    }, 
    watch: {
      
  },
    data() {
        return {
        loadingFlag: false,
        loadingPage: false,
        formLoading: false,
        showPassword: false,
        errorFlag: false,
        showOldPassword: false,
        admin:{
            login: '',
            password: '',
            current_password: '',
            role_id: 0,
            is_active: false,
            },
        }
    },
    async mounted() {
      disableAutocomplete.disable();
      this.loadingPage = true;
      this.$store.commit('setPageName', 'edit_admin');
      await this.toGetAdminByID();
      this.loadingPage = false;
    },
    methods: {
      getIdFromUrl() {
              const url = window.location.pathname;
              const parts = url.split('/'); 
              return parts[parts.length - 1];
            },
            async toGetAdminByID() {
        this.errorFlag = false;
        this.loadingFlag = true;
        try {
          const id = this.getIdFromUrl();
          await this.getAdmin(id);
        } catch (error) {
          this.errorFlag = true;
          this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
        } finally {
          this.loadingFlag = false;
        }
      },
            handleSubmit(validate) {
            validate().then(valid => {
              if (valid) {
                      this.edit();
              }
          });
          },
      async edit() {
        this.formLoading = true;
        try {
          await this.$store.dispatch('admins/awaitEditAdmin', this.admin);
            this.$toast.add({ severity: 'success', summary: this.$t('admin_edited'), life: 4000 });
            await this.$store.dispatch('admins/awaitGetAdmins');
            this.$router.push('/role-admins');
        } catch (error) {
          if (error.response && error.response.status === 403) {
            this.admin.current_password = '';
            this.$toast.add({ severity: 'error', summary: this.$t('wrong_password'), life: 4000 });
          } else{
             this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
           }
        } finally {
          this.formLoading = false;
        }
      },
      roleText(role) {
            switch (role) {
                case 'SUPERUSER':
                    return 'Super user';
                case 'ADMIN':
                    return 'Admin';
                case 'PROMO_MANAGER':
                    return 'Promo manager';
                case 'FINANCIAL_MANAGER':
                    return 'Financial manager';
                case 'GAME_MANAGER':
                    return 'Game manager';
                case 'PRODUCT_MANAGER':
                    return 'Product manager';
                case 'CFO':
                    return 'CFO';
                case 'CRM_MANAGER':
                    return 'CRM manager';
            }
        },
      async getAdmin(id){
        await axios
      .get(`/api/admins/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          this.admin = response.data;
        }
      })
      .catch((error) => {
        throw error;
      });
      },
    },
}
</script>

<style lang="scss" scoped>
.form-block{
  //max-height: 214px;
  //overflow: auto;
  display: flex;
  flex-direction: column;
  span{
    display: flex;
    align-items: center;
  }
  .title-input {
    flex: 0 0 200px;
    display: flex;
    //justify-content: flex-end;
  }
  .wrapper-input {
    //flex: 0 0 200px;
   // width: 100%;
  flex: 1 1 auto;
  }
  .v-input{
      min-width: 170px;
      max-width: max-content;
      margin-right: 5px;
      //&:not(:last-child) {
      //}
      margin-bottom: 6px;
      &.error--text{
          margin-bottom: 0;
      }
  }
  &_height {
      flex-direction: column;
      align-items: stretch;
  }

  &__header {
  }

  &__inputs {
      display: flex;
      flex-wrap: wrap;
      .v-input{
          @media (minh:500px){
              max-width: 170px;
              
          }
      }
  }
}
.half {
  display: flex;
  @media (max-width:850px){
    flex: 1 1 100%;
  }    
  @media (min-width:850px){
    flex-direction: column;
      
  }
  .v-input{
    @media (max-width:850px){
      margin-right: 3px;
      max-width: 50%;
      min-width: 50%;
  }

  }
}
</style>